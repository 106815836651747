<template>
  <div class="wrapper">
    <div class="progress-title">
      <span>Мій прогрес в акції</span>
      <img src="../../../../../assets/images/trophy.png" alt="Кубок" />
    </div>
    <div class="stat">Запрошено друзів: ...</div>
    <div class="stat">
      <span>Виконано умови: <b>...</b></span>
      <span>Нараховано: <b>...฿</b></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PromotionProgress',
}
</script>

<style scoped lang="scss">
.wrapper {
  width: 100%;
  margin: 8px auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  background: #d1eaff;
}

.progress-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
}

.stat {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}
</style>
