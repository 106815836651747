<template>
  <div v-if="content" class="header-wrapper" v-html="content" />
</template>

<script>
export default {
  name: 'PromotionHeader',

  props: {
    content: {
      type: String,
      default: 'Ваш рекламний заголовок',
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep(p) {
  margin-bottom: 0 !important;
}

.header-wrapper {
  width: 100%;
  max-height: 80px;
  margin: 8px 0;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-content: center;
  overflow: hidden;
  font-weight: 500;
  border: 2px solid rgba(255, 0, 0, 0.4);
  border-radius: 8px;
}
</style>
