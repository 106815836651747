<template>
  <div class="wrapper">
    <div class="title-wrapper">
      <div class="title">Ви отримаєте</div>
      <div class="value">350</div>
    </div>
    <v-text-field class="value-input" value="300 ₴" label="Сума поповнення" outlined dense hide-details readonly />
  </div>
</template>

<script>
export default {
  name: 'Calculator',
}
</script>

<style scoped lang="scss">
.wrapper {
  width: 90%;
  margin: 8px auto;
  display: flex;
  flex-direction: column;
  color: #ff103b;
  font-weight: 700;
}

.title-wrapper {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 16px;
}

.value {
  font-size: 22px;
}

.value-input {
  width: 150px;
  margin: 4px auto;
  font-size: 18px;
  font-weight: 700;
}
</style>
