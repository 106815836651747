<template>
  <div>
    <div class="tabs-wrapper">
      <div class="tab left active">ПОРУЧ</div>
      <div class="tab right">ОБРАНЕ</div>
    </div>
    <div class="device-card">
      <div class="device-renew">
        <v-icon color="success">mdi-autorenew</v-icon>
      </div>
      <div class="device-data">
        <div class="device-name">{{ spName }}</div>
        <div class="device-address">{{ spAddress }}</div>
        <div v-if="updatedAt" class="device-updated">Оновлено {{ updatedAt | getShortDate }} {{ updatedAt | getShortTime }}</div>
      </div>
      <div class="bookmark">
        <img src="../../../../../assets/images/bookmark.svg" alt="Закладка" width="16" height="22" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import convertDate from '@/mixins/convertDate'

export default {
  name: 'Device',

  mixins: [convertDate],

  props: {
    deviceId: {
      type: Number,
      required: true,
    },
    updatedAt: {
      type: String,
    },
  },

  watch: {
    async deviceId(val) {
      await this.loadSelectedDevice({ companyId: this.currentCompanyId, deviceId: val })
    },
  },

  created() {
    this.initialize()
  },

  computed: {
    ...mapState('devices', ['device']),

    currentCompanyId() {
      return this.$route.params.id
    },

    spName() {
      return this.device?.terminal?.service_point?.name || 'Назва торгової точки'
    },

    spAddress() {
      return this.device?.terminal?.service_point
        ? `${this.device?.terminal?.service_point.address.city}, ${this.device?.terminal?.service_point.address.street}, ${this.device?.terminal?.service_point.address.building_number}`
        : 'Адреса торгової точки'
    },
  },

  methods: {
    ...mapActions('devices', ['loadSelectedDevice']),

    async initialize() {
      await this.loadSelectedDevice({ companyId: this.currentCompanyId, deviceId: this.deviceId })
    },
  },
}
</script>

<style scoped lang="scss">
.tabs-wrapper {
  width: 100%;
  margin: 8px 0;
  display: flex;
  justify-content: center;
  background-color: white;
  box-shadow: 0 0.7px 2.09px 0.7px #00298f26;
  border-bottom: 1.4px solid #000;
  border-radius: 6px;
}

.tab {
  width: 50%;
  height: 100%;
  padding: 6.98px 8.38px 6.98px 8.38px;
  gap: 5.59px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}

.left {
  border-bottom-left-radius: 6px;
}

.right {
  border-bottom-left-radius: 6px;
}

.active {
  font-weight: 700;
  background: linear-gradient(180deg, #ffffff 0%, #caecff 100%);
  border-bottom: 1.4px solid #0063d8;
}

.device {
  &-card {
    width: 100%;
    height: 50px;
    margin: 4px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 1px 3px 1px #00298f26;
  }
  &-renew {
    width: 55px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-data {
    padding: 4px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    overflow: hidden;
  }
  &-name {
    font-size: 10px;
    font-weight: bold;
    color: #000;
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &-address {
    font-size: 10px;
    color: black;
  }
  &-updated {
    color: #545454;
    font-size: 10px;
  }
}

.bookmark {
  width: 24px;
  height: 100%;
  padding-top: 8px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
</style>
