<template>
  <div class="gradient-button">{{ title }}</div>
</template>

<script>
export default {
  name: 'GradientButton',

  props: {
    title: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped lang="scss">
.gradient-button {
  width: 90%;
  height: 36px;
  margin: 8px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: white;
  background: linear-gradient(90deg, #05e5fe 0%, #0d38f4 100%);
  text-transform: uppercase;
}
</style>
