<template>
  <div style="height: 100%">
    <div v-if="maxiBanner" class="banner-wrapper">
      <v-img :src="`${baseURL}/uploads/media/${maxiBanner.file_name}`" alt="Банер" />
      <div class="banner-cover">
        <p class="promotion-title">{{ `Акція "${promotion.title || 'Назва акції'}"` }}</p>
      </div>
    </div>
    <div v-else>
      <p class="promotion-title" style="color: black">{{ `Акція "${promotion.title}"` }}</p>
    </div>
    <div v-for="param in paramsSorted" :key="param.name">
      <PromotionHeader v-if="param.name === 'promotion_header'" :content="header" />
      <ServicePoint v-if="param.name === 'show_service_point'" :service-point="servicePoint" />
      <Device v-if="param.name === 'show_device'" :device-id="deviceId" :updated-at="deviceUpdated" />
      <Calculator v-if="param.name === 'show_calculator'"></Calculator>
      <PaymentButtons v-if="param.name === 'bonus_account_replenishment'" title="Поповнити" />
      <BonusAccount v-if="param.name === 'show_customer_bonus_account'" />
      <GradientButton v-if="param.name === 'invite_friend'" title="Запросити" />
      <PromotionProgress v-if="param.name === 'show_promotion_progress'"></PromotionProgress>
    </div>
    <div class="footer">
      <div class="footer-title text-truncate">Умови акції</div>
      <div class="footer-description overflow-y-auto" v-html="promotion.description" />
      <div v-if="promotion.start_at" class="footer-terms">
        <span>Термін дії з {{ promotion.start_at | getShortDate }}</span>
        <span v-if="promotion.expired_at"> до {{ promotion.expired_at | getShortDate }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import ServicePoint from '@/components/marketing/promotions/preview/params/ServicePoint'
import PromotionHeader from '@/components/marketing/promotions/preview/params/PromotionHeader'
import convertDate from '@/mixins/convertDate'
import Device from '@/components/marketing/promotions/preview/params/Device'
import GradientButton from '@/components/marketing/promotions/preview/params/GradientButton'
import BonusAccount from '@/components/marketing/promotions/preview/params/BonusAccount'
import Calculator from '@/components/marketing/promotions/preview/params/Calculator'
import PromotionProgress from '@/components/marketing/promotions/preview/params/PromotionProgress'
import PaymentButtons from '@/components/marketing/promotions/preview/params/PaymentButtons.vue'

export default {
  name: 'MobilePreview',
  components: {
    PaymentButtons,
    PromotionProgress,
    Calculator,
    BonusAccount,
    GradientButton,
    Device,
    PromotionHeader,
    ServicePoint,
  },

  mixins: [convertDate],

  props: {
    promotion: {
      type: Object,
      required: true,
    },
    header: {
      type: String,
      default: null,
    },
    maxiBanner: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_API_URL,
      params: [],
    }
  },

  watch: {
    promotion: {
      handler(val) {
        this.getParams(val.params.flag_params)
      },
      deep: true,
    },
  },

  created() {
    this.initialize()
  },

  computed: {
    servicePoint() {
      return this.promotion.service_points[0] || null
    },

    deviceId() {
      return this.promotion.devices[0].id || 0
    },

    deviceUpdated() {
      return this.promotion.devices[0].updated_at || null
    },

    paramsSorted() {
      return [...this.params].sort((a, b) => a.sort_priority - b.sort_priority)
    },
  },

  methods: {
    getParams(params) {
      this.params = [
        ...Object.entries(params)
          .filter(([, v]) => !!v.enabled)
          .map(([key, value]) => ({ name: key, sort_priority: value.sort_priority })),
      ]
      const headerParam = this.promotion?.params?.promotion_header
      if (headerParam && !this.params.find((param) => param.name === 'promotion_header')) {
        this.params.push({ name: 'promotion_header', sort_priority: headerParam.sort_priority })
      }
    },

    initialize() {
      this.getParams(this.promotion.params.flag_params)
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep(p) {
  margin-bottom: 0 !important;
}

.banner-wrapper {
  width: 100%;
  position: relative;
}

.banner-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 240px;
  height: 166px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  background: transparent;
}

.promotion-title {
  width: 240px;
  padding: 4px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.footer {
  width: 100%;
  margin: 8px auto;
  padding: 10px;
  position: relative;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  box-shadow: 0 1px 3px 1px #00298f26;
  &-title {
    width: 100%;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
  }
  &-description {
    max-height: 160px;
    padding: 10px;
  }
  &-terms {
    padding: 10px;
    font-size: 11px;
    font-weight: bold;
  }
}
</style>
