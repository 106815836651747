<template>
  <div>
    <div class="payment-button">
      <img src="../../../../../assets/images/liqpay.svg" alt="LiqPay" width="22" height="16" />
      <span>LiqPay</span>
    </div>
    <div class="payment-button">
      <img src="../../../../../assets/images/google_pay.svg" alt="GooglePay" width="22" height="16" />
      <span>Google Pay</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentButtons',
}
</script>

<style scoped lang="scss">
.payment-button {
  width: 90%;
  height: 40px;
  margin: 8px auto;
  padding: 0 20px;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  font-size: 10px;
  font-weight: bold;
  color: black;
  background: white;
  text-transform: uppercase;
  box-shadow: 0 1px 2px 1px #00298f26;
}
</style>
