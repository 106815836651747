<template>
  <div>
    <div class="tabs-wrapper">
      <div class="tab left active">ВСІ</div>
      <div class="tab">ПОРУЧ</div>
      <div class="tab right">ОБРАНЕ</div>
    </div>
    <div class="sp-card">
      <div class="sp-logo">
        <img src="../../../../../assets/images/wm-aquamat.svg" alt="Пралка+Аквамат" width="36" height="40" />
      </div>
      <div class="sp-data">
        <div class="sp-name">{{ spName }}</div>
        <div class="sp-address">{{ spAddress }}</div>
        <div class="sp-geo">
          <img src="../../../../../assets/images/geo-pin.svg" alt="Гео-мітка" width="12" height="12" />
          <span>Показати на карті</span>
        </div>
      </div>
      <div class="bookmark">
        <img src="../../../../../assets/images/bookmark.svg" alt="Закладка" width="16" height="22" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServicePoint',

  props: {
    servicePoint: {
      type: Object,
      required: true,
    },
  },

  computed: {
    spName() {
      return this.servicePoint?.name || 'Назва торгової точки'
    },

    spAddress() {
      return this.servicePoint
        ? `${this.servicePoint.address.city}, ${this.servicePoint.address.street}, ${this.servicePoint.address.building_number}`
        : 'Адреса торгової точки'
    },
  },
}
</script>

<style scoped lang="scss">
.tabs-wrapper {
  width: 100%;
  margin: 8px 0;
  display: flex;
  justify-content: center;
  background-color: white;
  box-shadow: 0 0.7px 2.09px 0.7px #00298f26;
  border-bottom: 1.4px solid #000;
  border-radius: 6px;
}

.tab {
  width: 33.333%;
  height: 100%;
  padding: 6.98px 8.38px 6.98px 8.38px;
  gap: 5.59px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}

.left {
  border-bottom-left-radius: 6px;
}

.right {
  border-bottom-left-radius: 6px;
}

.active {
  font-weight: 700;
  background: linear-gradient(180deg, #ffffff 0%, #caecff 100%);
  border-bottom: 1.4px solid #0063d8;
}

.sp {
  &-card {
    width: 100%;
    height: 50px;
    margin: 8px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 1px 3px 1px #00298f26;
  }

  &-logo {
    width: 55px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #ffffff 0%, #caecff 100%);
  }

  &-data {
    padding: 4px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    overflow: hidden;
  }

  &-name {
    font-size: 10px;
    font-weight: bold;
    color: #000;
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &-address {
    font-size: 8px;
    color: #545454;
  }

  &-geo {
    display: flex;
    font-size: 10px;
    font-weight: bold;
    color: #0063d8;
    text-decoration: underline;
  }
}

.bookmark {
  width: 24px;
  height: 100%;
  padding-top: 8px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
</style>
